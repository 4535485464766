<template>
  <div>
    <div class="form-card text-right">
      <div class="mb-3">
        <h6>العقود</h6>

        <InputText
          v-model="body.contractsMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.contracts"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>الحسابات</h6>
        <InputText
          v-model="body.collectionMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.collection"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>المخازن</h6>
        <InputText
          v-model="body.materialsMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.materials"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>متابعة العملاء المستهدفين</h6>
        <InputText
          v-model="body.tasksMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.tasks"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>اوامر التركيب</h6>
        <InputText
          v-model="body.installationsMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.installations"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>توزيع اوامر التركيب</h6>
        <InputText
          v-model="body.distributionsMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.distributions"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>اوامر التشغيل</h6>
        <InputText
          v-model="body.operationsMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.operations"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>مراجعة اوامر التشغيل</h6>
        <InputText
          v-model="body.revisionsMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.revisions"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>ملاحظات تسليم العملاء</h6>
        <InputText
          v-model="body.delivery_notesMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.delivery_notes"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>تسليم العملاء</h6>
        <InputText
          v-model="body.deliveryMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.delivery"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>اغلاق العقود</h6>
        <InputText
          v-model="body.welcomeMsg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.welcome"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>رصيد افتتاحي</h6>
        <InputText
          v-model="body.inventory_openingBalance_Msg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.inventory_openingBalance"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>استلام مخزني</h6>
        <InputText
          v-model="body.inventory_receivingInventory_Msg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.inventory_receivingInventory"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>استلام مخزني</h6>
        <InputText
          v-model="body.inventory_inventoryStoreCountingReq_Msg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.inventory_inventoryStoreCountingReq"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>صرف مخزني</h6>
        <InputText
          v-model="body.inventory_inventoryStoreCounting_Msg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.inventory_inventoryStoreCounting"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>جرد مخزني</h6>
        <InputText
          v-model="body.inventory_inventoryAdjustment_Msg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.inventory_inventoryAdjustment"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>اتلاف مخزني</h6>
        <InputText
          v-model="body.inventory_inventoryDamageStoreItems_Msg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.inventory_inventoryDamageStoreItems"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3">
        <h6>تحويل مخزني</h6>
        <InputText
          v-model="body.inventory_inventoryDirectTransfer_Msg"
          style="width: 100%;"
          placeholder="رساله الاشعار"
          class="mb-3"
        />
        <MultiSelect  dataKey="id"
          v-model="body.inventory_inventoryDirectTransfer"
          :options="roles"
          optionLabel="name"
          optionValue="id"
          placeholder="اختر مجموعات المستخدمين"
          display="chip"
          style="width: 100%;"
        />
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          @click="update()"
          :disabled="disabledAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        contracts: [],
        collection: [],
        materials: [],
        tasks: [],
        installations: [],
        distributions: [],
        operations: [],
        revisions: [],
        delivery_notes: [],
        delivery: [],
        welcome: [],
        inventory_openingBalance: [],
        inventory_receivingInventory: [],
        inventory_inventoryStoreCountingReq: [],
        inventory_inventoryStoreCounting: [],
        inventory_inventoryAdjustment: [],
        inventory_inventoryDamageStoreItems: [],
        inventory_inventoryDirectTransfer: [],
        contractsMsg: null,
        collectionMsg: null,
        materialsMsg: null,
        tasksMsg: null,
        installationsMsg: null,
        distributionsMsg: null,
        operationsMsg: null,
        revisionsMsg: null,
        delivery_notesMsg: null,
        deliveryMsg: null,
        welcomeMsg: null,
        inventory_openingBalance_Msg: null,
        inventory_receivingInventory_Msg: null,
        inventory_inventoryStoreCountingReq_Msg: null,
        inventory_inventoryStoreCounting_Msg: null,
        inventory_inventoryAdjustment_Msg: null,
        inventory_inventoryDamageStoreItems_Msg: null,
        inventory_inventoryDirectTransfer_Msg: null,
      },
      disabledAdd: false,
    };
  },
  methods: {
    update() {
      this.disabledAdd = true;
      this.$http.put(`notifications`, this.body).then(
        () => {
          this.disabledAdd = false;
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.disabledAdd = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    if (!this.$checkRoles('notificationsVeiw')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`roles`).then((res) => {
      this.roles = res.data;
    });
    this.$http.get(`notifications`).then(
      (res) => {
        this.body = res.data;
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );
  },
};
</script>
